import React, { useState, useRef } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { TextField } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { marker } from 'leaflet';
import HomeMarkerIcon from '../../../assets/images/home-Icon.svg';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import service from './service';
import { toast } from 'react-toastify';

const Users = () => {
  const mapRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [mapCenter, setMapCenter] = useState([
    37.87244919634651, 32.49206387333341,
  ]);
  const [mapZoom, setMapZoom] = useState(16);
  const [markerPosition, setMarkerPosition] = useState([
    37.87244919634651, 32.49206387333341,
  ]);
  const [draggedLocation, setDraggedLocation] = useState({
    lat: null,
    lng: null,
    address: '',
  });
  const [mapPolygon, setMapPolygon] = useState([]);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const tileLayer =
    'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png';

  const markerRef = useRef();
  const [studentName, setStudentName] = useState('');
  const [studentSurname, setStudentSurname] = useState('');
  const [studentGateNo, setStudentGateNo] = useState(null);
  const [studentPhone, setStudentPhone] = useState('');
  const [studentSchool, setStudentSchool] = useState('');
  const [studentMail, setStudentMail] = useState('');
  const [studentIdentity, setStudentIdentity] = useState('');
  const handleSaveLocation = async () => {
    setSaveModalOpen(!saveModalOpen);
  };

  const searchPlace = async (query) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          query + ', Konya , Türkiye',
        )}&format=json&addressdetails=1&limit=1`,
      );
      if (response && response.data && response.data[0]) {
        return response.data[0];
      } else {
        throw new Error('Location not found');
      }
    } catch (error) {
      console.error('Error searching place:', error);
      return null;
    }
  };

  const searchPlaceWithPolygon = async (placeName) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(
          placeName + ', Konya , Türkiye',
        )}&polygon_geojson=1&format=jsonv2`,
      );
      if (response && response.data && response.data[0]) {
        const polygon = response.data[0].geojson.coordinates[0];
        const reverseXY = (coord) => {
          return [coord[1], coord[0]];
        };
        const reversedPolygon = polygon.map(reverseXY);
        return reversedPolygon;
      } else {
        throw new Error('Polygon not found');
      }
    } catch (error) {
      console.error('Error searching place with polygon:', error);
      return null;
    }
  };

  const handleMarkerDragEnd = async (e) => {
    const newPos = e.target.getLatLng();
    setMarkerPosition(newPos);
    try {
      const address = await reverseGeocode(newPos.lat, newPos.lng);
      if (address) {
        const { town, province: city, suburb, road } = address.address;
        const addressString = `${suburb ? suburb + ', ' : ''}${
          road ? road + ', ' : ''
        }${town ? town + ', ' : ''}${city ? city : ''}`;
        setDraggedLocation({
          lat: newPos.lat,
          lng: newPos.lng,
          address: addressString,
        });
      }
    } catch (error) {
      console.error('Error fetching address after drag:', error);
    }
  };

  const reverseGeocode = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`,
      );
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Address not found');
      }
    } catch (error) {
      console.error('Error during reverse geocoding:', error);
      return null;
    }
  };

  const handleSaveCoordinate = async () => {
    try {
      const payload = {
        userName: studentName,
        userSurname: studentSurname,
        userAddress: draggedLocation.address + ', Kapı No : ' + studentGateNo,
        userPhone: studentPhone,
        userEmail: studentMail,
        userStatus: studentSchool,
        userPassword: studentPhone,
        userRole: studentIdentity,
        userCoordinate: `${markerPosition.lat},${markerPosition.lng}`,
      };
      if (!markerPosition.lat || !markerPosition.lng) {
        toast.error(
          'Lütfen konum ikonunu haritada evinizin üzerine sürükleyiniz',
        );
        return;
      } else if (!payload.userName || !payload.userSurname) {
        toast.error('Lütfen ad ve soyad alanları doldurunuz');
        return;
      } else if (!payload.userAddress) {
        toast.error('Lütfen adres alanını doldurunuz');
        return;
      } else if (!studentGateNo) {
        toast.error('Lütfen bina numarasını giriniz');
        return;
      } else if (!payload.userRole) {
        toast.error('Lütfen kimlik numarasını giriniz');
        return;
      }

      const response = await service.saveUser(payload);
      if (response.status === 200) {
        toast.success('Başarılı bir şekilde kaydedildi');
        setSaveModalOpen(false);
      } else {
        throw new Error('Failed to save user');
      }
    } catch (error) {
      console.error('Error saving coordinate:', error);
      toast.error('Kayıt başarısız');
    }
  };

  const handleSearchButtonClick = async () => {
    setIsLoading(true);
    try {
      const polygon = await searchPlaceWithPolygon(searchParam);
      const place = await searchPlace(searchParam);

      if (place) {
        setMarkerPosition([place.lat, place.lon]);
        setMapCenter([place.lat, place.lon]);
        setMapZoom(
          place.type === 'city'
            ? 10
            : place.type === 'administrative'
              ? 15
              : 16,
        );
        setMapPolygon(polygon);
      } else {
        throw new Error('Place not found');
      }
    } catch (error) {
      console.error('Error searching place or polygon:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCenterMarker = async () => {
    const mapInstance = mapRef.current;
    if (mapInstance) {
      const center = mapInstance.getCenter();
      setMarkerPosition([center.lat, center.lng]);
      try {
        const address = await reverseGeocode(center.lat, center.lng);
        if (address) {
          const { town, province: city, suburb, road } = address.address;
          const addressString = `${suburb ? suburb + ', ' : ''}${
            road ? road + ', ' : ''
          }${town ? town + ', ' : ''}${city ? city : ''}`;
          setDraggedLocation({
            lat: center.lat,
            lng: center.lng,
            address: addressString,
          });
        }
      } catch (error) {
        console.error('Error fetching address for centered marker:', error);
      }
    }
  };

  const HomeIcon = L.icon({
    iconUrl: HomeMarkerIcon,
    iconSize: [38, 38],
    iconAnchor: [19, 38], // Anchor'ın alt orta noktası
    popupAnchor: [0, -38], // Popup'ın Marker'ın üst orta noktasına oturmasını sağlar
  });

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="max-lg:w-full px-2 md:px-16">
        <div className="px-2 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg font-[Inter] sm:text-2xl max-sm:text-center">
            Dağıtımlar
          </span>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-md shadow-xl m-3 w-full max-lg:w-full">
              <div className="flex items-start justify-center lg:justify-between p-3 w-full flex-col gap-4">
                <span className="text-sm font-[Inter] sm:text-xs italic">
                  Konumunuzu belirlemek için aşağıdaki adımları izleyiniz:
                  <br />
                  1. Mahalle adını giriniz ve &quot;Ara&quot; butonuna basınız.
                  Örneğin, &quot;Sancak Mahallesi&quot;.
                  <br />
                  2. Harita üzerinde bulunan sürüklenebilir ikonu evinizin
                  üzerine taşıyınız. İkonu bulmakta zorlanırsanız &quot;Konumu
                  Merkezle&quot; butonuna tıklayarak haritayı evinizin konumuna
                  odaklayabilirsiniz.
                  <br />
                  3. İkonu evinizin üzerine bıraktıktan sonra ikon üzerinde
                  beliren &quot;Konumu Kaydet&quot; butonuna tıklayınız.
                  <br />
                  4. Açılan formda öğrenci bilgilerinizi giriniz.
                  <br />
                  5. Son olarak &quot;Kaydet&quot; butonuna basarak işlemi
                  tamamlayınız.
                </span>
                <TextField
                  id="outlined-basic"
                  label="Mahalle giriniz"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearchButtonClick();
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  value={searchParam}
                  size="small"
                  onChange={(e) => setSearchParam(e.target.value)}
                />
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleSearchButtonClick}
                >
                  Konumu Ara
                </Button>
              </div>
            </div>
            <div className="flex flex-row justify-end m-3 w-full max-lg:w-full">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCenterMarker}
              >
                Konumu Merkezle
              </Button>
            </div>
            <div
              id="map"
              className="bg-white rounded-xl shadow-md flex flex-col gap-4 m-3 w-full max-lg:w-full"
            >
              <MapContainer
                ref={mapRef}
                className="rounded-md"
                center={mapCenter}
                zoom={mapZoom}
                attributionControl={false}
                scrollWheelZoom={true}
                style={{ height: '600px', width: '100%' }}
                eventHandlers={{
                  moveend: () => {
                    if (markerRef.current) {
                      const position = markerRef.current.getLatLng();
                      setMarkerPosition([position.lat, position.lng]);
                    }
                  },
                }}
              >
                <TileLayer url={tileLayer} />

                <Polygon
                  pathOptions={{ fillColor: 'blue' }}
                  positions={mapPolygon}
                />
                <Marker
                  position={markerPosition}
                  icon={HomeIcon}
                  draggable={true}
                  eventHandlers={{
                    dragend: handleMarkerDragEnd,
                  }}
                  ref={markerRef}
                >
                  <Popup>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSaveLocation();
                      }}
                    >
                      Konumu Kaydet
                    </Button>
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </>
        )}
      </div>
      <Modal
        className="flex items-center justify-center"
        open={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
      >
        <div
          className="bg-white flex flex-col gap-2 rounded-md shadow-xl m-3 md:w-1/2 w-full p-8
        "
        >
          <TextField
            id="outlined-basic"
            label="Öğrenci Adı"
            variant="outlined"
            fullWidth
            size="small"
            required
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Öğrenci Soyadı"
            variant="outlined"
            fullWidth
            size="small"
            required
            value={studentSurname}
            onChange={(e) => setStudentSurname(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Öğrencinin T.C Kimlik Numarası"
            variant="outlined"
            fullWidth
            required
            size="small"
            inputProps={{ maxLength: 11 }} // Maksimum karakter sayısı 11
            value={studentIdentity}
            onChange={(e) => setStudentIdentity(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Öğrenci Adresi"
            variant="outlined"
            required
            fullWidth
            size="small"
            value={draggedLocation.address}
            onChange={(e) =>
              setDraggedLocation({
                ...draggedLocation,
                address: e.target.value,
              })
            }
          />
          <TextField
            id="outlined-basic"
            label="Bina No"
            variant="outlined"
            fullWidth
            required
            size="small"
            value={studentGateNo}
            onChange={(e) => setStudentGateNo(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Öğrenci Telefonu"
            variant="outlined"
            fullWidth
            size="small"
            value={studentPhone}
            onChange={(e) => setStudentPhone(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Öğrenci Okulu"
            variant="outlined"
            fullWidth
            size="small"
            value={studentSchool}
            onChange={(e) => setStudentSchool(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Öğrenci Maili"
            variant="outlined"
            fullWidth
            size="small"
            value={studentMail}
            onChange={(e) => setStudentMail(e.target.value)}
          />
          <div className="flex justify-around gap-2">
            <Button
              variant="contained"
              className="w-1/3"
              color="error"
              onClick={() => setSaveModalOpen(false)}
            >
              İptal
            </Button>
            <Button
              className="w-1/3"
              variant="contained"
              color="primary"
              onClick={handleSaveCoordinate}
            >
              Kaydet
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
