const permissions = JSON.parse(localStorage.getItem('permissions'))?.data?.data
  ?.permissions;
const roles = JSON.parse(localStorage.getItem('permissions'))?.data?.data
  ?.roles;
const instance = JSON.parse(localStorage.getItem('instance'));
const selectedInstance = JSON.parse(localStorage.getItem('selectedInstance'));

const checkPermission = {
  hasRole(role) {
    return roles.includes(role);
  },

  hasPermission(permission) {
    return permissions.includes(permission);
  },

  hasInstance(ins) {
    if (instance) {
      return ins.includes(
        selectedInstance ? selectedInstance[0].id : instance[0].instance.id,
      );
    }
  },
};

export default checkPermission;
