import React from 'react';

function FullLayout({ children }) {
  return (
    <div className="flex flex-col mx-20 max-sm:mx-2 justify-center">
      {children}
    </div>
  );
}

export default FullLayout;
