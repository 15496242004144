import React, { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { List, ListItem, Tab, Collapse } from '@mui/material';

import checkPermission from '../../../utils/checkPermission';
import { items } from '../constants/header_items';

export default function SideMenu() {
  const [collapsed, setCollapsed] = useState(false);
  const [secondCollapsed, setSecondCollapsed] = useState(false);

  const location = useLocation();
  return (
    <List
      sx={{
        marginY: '30px',
        marginX: '25px',
        padding: '10px',
        borderRadius: '20px',
      }}
    >
      {items.map((item, index) => (
        <div key={index}>
          {item.instanceCanView ||
          item.roleCanView ||
          item.permissionCanView ? (
            checkPermission.hasInstance(item.instanceCanView) ||
            checkPermission.hasRole(item.roleCanView) ||
            checkPermission.hasPermission(item.permissionCanView) ? (
              <ListItem
                component={Link}
                to={item.to}
                onClick={() => {
                  if (item.child && item.child.length > 0) {
                    setCollapsed({
                      ...collapsed,
                      [item.id]: !collapsed[item.id],
                    });
                  }
                }}
              >
                <Tab
                  sx={{
                    pl: 3,
                    width: '100%',
                    minHeight: '40px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                  disableRipple
                  label={item.parent}
                  iconPosition="start"
                  icon={item.icon}
                ></Tab>
              </ListItem>
            ) : null
          ) : (
            <ListItem
              component={Link}
              to={item.to}
              onClick={() => {
                if (item.child && item.child.length > 0) {
                  setCollapsed({
                    ...collapsed,
                    [item.id]: !collapsed[item.id],
                  });
                }
              }}
            >
              <Tab
                sx={{
                  pl: 3,
                  width: '100%',
                  minHeight: '40px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                disableRipple
                label={item.parent}
                iconPosition="start"
                icon={item.icon}
              ></Tab>
            </ListItem>
          )}

          {item.child && item.child.length > 0 && (
            <Collapse in={collapsed[item.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.child.map((childItem, index) => (
                  <div key={index}>
                    {childItem.instanceCanView ||
                    childItem.roleCanView ||
                    childItem.permissionCanView ? (
                      checkPermission.hasInstance(
                        childItem.instanceCanView ||
                          checkPermission.hasRole(childItem.roleCanView) ||
                          checkPermission.hasPermission(
                            childItem.permissionCanView,
                          ),
                      ) ? (
                        <ListItem
                          component={Link}
                          to={childItem.to}
                          sx={{
                            backgroundColor:
                              location.pathname === childItem.to
                                ? '#e5e5e5'
                                : 'inherit',
                            pl: 2,
                            color:
                              location.pathname === childItem.to
                                ? 'black'
                                : 'inherit',
                            borderRadius: '10px',
                          }}
                          onClick={() => {
                            if (childItem.child && childItem.child.length > 0) {
                              setSecondCollapsed({
                                ...secondCollapsed,
                                [childItem.id]: !secondCollapsed[childItem.id],
                              });
                            }
                          }}
                        >
                          <Tab
                            sx={{
                              pl: 6,
                              width: '100%',
                              minHeight: '35px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                            disableRipple
                            label={childItem.parent || childItem.name}
                            iconPosition="start"
                            icon={childItem.icon}
                          ></Tab>
                        </ListItem>
                      ) : null
                    ) : (
                      <ListItem
                        component={Link}
                        to={childItem.to}
                        sx={{
                          backgroundColor:
                            location.pathname === childItem.to
                              ? '#e5e5e5'
                              : 'inherit',
                          pl: 2,
                          color:
                            location.pathname === childItem.to
                              ? 'black'
                              : 'inherit',
                          borderRadius: '10px',
                        }}
                        onClick={() => {
                          if (childItem.child && childItem.child.length > 0) {
                            setSecondCollapsed({
                              ...secondCollapsed,
                              [childItem.id]: !secondCollapsed[childItem.id],
                            });
                          }
                        }}
                      >
                        <Tab
                          sx={{
                            pl: 6,
                            width: '100%',
                            minHeight: '35px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                          disableRipple
                          label={childItem.parent || childItem.name}
                          iconPosition="start"
                          icon={childItem.icon}
                        ></Tab>
                      </ListItem>
                    )}

                    {childItem.child && childItem.child.length > 0 && (
                      <Collapse
                        in={secondCollapsed[childItem.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {childItem.child.map((grandChildItem, index) => (
                            <div key={index}>
                              {grandChildItem.instanceCanView ||
                              grandChildItem.roleCanView ||
                              grandChildItem.permissionCanView ? (
                                (checkPermission.hasInstance(
                                  grandChildItem.instanceCanView ||
                                    checkPermission.hasRole(
                                      grandChildItem.roleCanView,
                                    ) ||
                                    checkPermission.hasPermission(
                                      grandChildItem.permissionCanView,
                                    ),
                                ) && (
                                  <ListItem
                                    key={grandChildItem.id}
                                    component={Link}
                                    to={grandChildItem.to}
                                    sx={{
                                      backgroundImage:
                                        location.pathname === grandChildItem.to
                                          ? 'linear-gradient(90deg, rgba(69,188,240,1) 0%, rgba(108,210,255,1) 63%)'
                                          : 'inherit',
                                      pl: 2,
                                      color:
                                        location.pathname === grandChildItem.to
                                          ? 'white'
                                          : 'inherit',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    <Tab
                                      sx={{
                                        pl: 9,
                                        width: '100%',
                                        minHeight: '30px',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                      }}
                                      disableRipple
                                      label={grandChildItem.name}
                                      iconPosition="start"
                                      icon={grandChildItem.icon}
                                    ></Tab>
                                  </ListItem>
                                )) ||
                                null
                              ) : (
                                <ListItem
                                  key={grandChildItem.id}
                                  component={Link}
                                  to={grandChildItem.to}
                                  sx={{
                                    backgroundImage:
                                      location.pathname === grandChildItem.to
                                        ? 'linear-gradient(90deg, rgba(69,188,240,1) 0%, rgba(108,210,255,1) 63%)'
                                        : 'inherit',
                                    pl: 2,
                                    color:
                                      location.pathname === grandChildItem.to
                                        ? 'white'
                                        : 'inherit',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <Tab
                                    sx={{
                                      pl: 9,
                                      width: '100%',
                                      minHeight: '30px',
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                    }}
                                    disableRipple
                                    label={grandChildItem.name}
                                    iconPosition="start"
                                    icon={grandChildItem.icon}
                                  ></Tab>
                                </ListItem>
                              )}
                            </div>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </div>
                ))}
              </List>
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );
}
