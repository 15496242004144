import { filterList } from './table_columns_data';

export const FILTER_DATA = filterList.reduce((accumulator, item) => {
  const newAccumulator = { ...accumulator };
  newAccumulator[item.value] = '';
  return newAccumulator;
}, {});

export const COUNT = 0;
export const VEHICLEID = 0;
export const SHIFTID = 0;
export const IS_LOADING = false;
export const IS_LOADINGONCLICK = false;
export const PAGE = 0;
export const ROUTES = [];
export const SORT_MODEL = [];
export const SHIFTS = [];
export const SCHEMA = [];
export const POINTS = [];
export const MARKERS = [];
export const STARTCOORDINATES = [];
export const DISTANCE = 0;
export const DURATION = 0;
export const PERSONELDATA = [];
export const DRAGGEDPERSONELDATA = null;
export const TASKS = [];
export const SELECTEDTASK = [];
export const SELECTEDTASKPOINTS = [];
export const SELECTEDTASKMARKERS = [];
export const MARKERCOORDINATES = [];
export const EMPLOYEESDATA = [];
export const SELECTEDTASKDISTANCE = [];
export const SELECTEDTASKENDTIME = null;
export const SELECTEDTASKSTARTTIME = null;
