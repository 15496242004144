const updateLocalStorage = (wsConfig) => {
  localStorage.setItem('wsConfig', JSON.stringify(wsConfig));
};

const createWsConfigStore = (set) => ({
  wsConfig: localStorage.getItem('wsConfig')
    ? JSON.parse(localStorage.getItem('wsConfig'))
    : null,
  setWsConfig: (wsConfig) => {
    set(() => ({ wsConfig }));
    updateLocalStorage(wsConfig);
  },
});

export default createWsConfigStore;
