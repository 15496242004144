import React from 'react';
import Empty from '../../assets/images/empty.png';

const ErrorPage = () => {
  return (
    <div className=" w-full h-screen flex justify-center items-start">
      <div className="flex flex-col justify-center items-center m-5 p-5 bg-white shadow-2xl rounded-2xl">
        <h1 className="font-bold text-3xl">404</h1>
        <img src={Empty} className="w-36 h-36" alt="Bulunamadı" />
        <h1 className="font-bold text-xl">Burada hiçbir şey yok.</h1>
        <h1 className="font-bold text-xl">Aradığın sayfayı bulamadık.</h1>
      </div>
    </div>
  );
};

export default ErrorPage;
