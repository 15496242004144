export const columns = [
  {
    field: 'number',
    headerName: 'No',
    width: 70,
    renderCell: (data) => {
      return <span>{data.row.number}</span>;
    },
  },
  {
    field: 'name',
    headerName: 'İsim',
    width: 120,
    renderCell: (data) => {
      return <span>{data.row.name}</span>;
    },
  },
  {
    field: 'surname',
    headerName: 'Soyisim',
    width: 120,
    renderCell: (data) => {
      return <span>{data.row.surname}</span>;
    },
  },
  {
    field: 'address',
    headerName: 'Adres',
    width: 230,
    renderCell: (data) => {
      return (
        <span className="relative inline-block max-w-[20ch] whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer">
          {data.row.address}
        </span>
      );
    },
  },
  {
    field: 'phone',
    headerName: 'Telefon',
    width: 150,
    renderCell: (data) => {
      return <span>{data.row.phone}</span>;
    },
  },
  {
    field: 'secondPhone',
    headerName: 'İkinci Telefon',
    width: 150,
    renderCell: (data) => {
      return <span>{data.row.secondPhone}</span>;
    },
  },
  {
    field: 'schema',
    headerName: 'Şema',
    width: 80,
    renderCell: (data) => {
      return <span>{data.row.schema}</span>;
    },
  },
];

export const filterList = [
  { value: 'id', text: 'Kullanıcı ID' },
  { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'email', text: 'Email' },
  { value: 'gsm', text: 'Telefon' },
];
