import { filterList } from './table_columns_data';

export const FILTER_DATA = filterList.reduce((accumulator, item) => {
  const newAccumulator = { ...accumulator };
  newAccumulator[item.value] = '';
  return newAccumulator;
}, {});

export const COUNT = 0;
export const VEHICLESIZE = 0;
export const SHIFTID = 0;
export const MINSIZE = 0;
export const MAXSIZE = 0;
export const IS_LOADING = false;
export const PAGE = 0;
export const TASKS = [];
export const SORT_MODEL = [];
export const DISTRIBUTION = [];
export const DISTRIBUTIONEMLPOYEE = [];
export const MERGEDDATA = [];
export const VEHICLES = [];
export const PERSONELADRESSTYPE = null;
export const USERS = [];
export const CITIES = [
  {
    id: 1,
    name: 'Adana',
    maxLongitude: 35.35,
    minLongitude: 34.82,
    maxLatitude: 37.08,
    minLatitude: 36.5,
  },
  {
    id: 2,
    name: 'Adıyaman',
    maxLongitude: 38.13,
    minLongitude: 37.56,
    maxLatitude: 38.03,
    minLatitude: 37.37,
  },
  {
    id: 3,
    name: 'Afyonkarahisar',
    maxLongitude: 31.47,
    minLongitude: 29.72,
    maxLatitude: 39.16,
    minLatitude: 37.95,
  },
  {
    id: 4,
    name: 'Ağrı',
    maxLongitude: 45.0,
    minLongitude: 42.5,
    maxLatitude: 40.07,
    minLatitude: 39.22,
  },
  {
    id: 5,
    name: 'Amasya',
    maxLongitude: 36.59,
    minLongitude: 34.86,
    maxLatitude: 41.36,
    minLatitude: 40.5,
  },
  {
    id: 6,
    name: 'Ankara',
    maxLongitude: 33.06,
    minLongitude: 31.18,
    maxLatitude: 40.37,
    minLatitude: 39.31,
  },
  {
    id: 7,
    name: 'Antalya',
    maxLongitude: 31.61,
    minLongitude: 29.32,
    maxLatitude: 37.29,
    minLatitude: 36.07,
  },
  {
    id: 8,
    name: 'Artvin',
    maxLongitude: 42.02,
    minLongitude: 40.35,
    maxLatitude: 41.64,
    minLatitude: 40.83,
  },
  {
    id: 9,
    name: 'Aydın',
    maxLongitude: 28.53,
    minLongitude: 27.11,
    maxLatitude: 38.16,
    minLatitude: 37.45,
  },
  {
    id: 10,
    name: 'Balıkesir',
    maxLongitude: 28.48,
    minLongitude: 26.54,
    maxLatitude: 40.35,
    minLatitude: 39.16,
  },
  {
    id: 11,
    name: 'Bilecik',
    maxLongitude: 30.35,
    minLongitude: 29.5,
    maxLatitude: 40.26,
    minLatitude: 39.9,
  },
  {
    id: 12,
    name: 'Bingöl',
    maxLongitude: 40.86,
    minLongitude: 39.43,
    maxLatitude: 39.15,
    minLatitude: 38.36,
  },
  {
    id: 13,
    name: 'Bitlis',
    maxLongitude: 43.9,
    minLongitude: 41.78,
    maxLatitude: 39.16,
    minLatitude: 38.06,
  },
  {
    id: 14,
    name: 'Bolu',
    maxLongitude: 31.79,
    minLongitude: 30.18,
    maxLatitude: 41.06,
    minLatitude: 40.3,
  },
  {
    id: 15,
    name: 'Burdur',
    maxLongitude: 30.04,
    minLongitude: 29.0,
    maxLatitude: 37.82,
    minLatitude: 37.0,
  },
  {
    id: 16,
    name: 'Bursa',
    maxLongitude: 29.69,
    minLongitude: 28.03,
    maxLatitude: 40.42,
    minLatitude: 39.62,
  },
  {
    id: 17,
    name: 'Çanakkale',
    maxLongitude: 27.3,
    minLongitude: 25.67,
    maxLatitude: 40.59,
    minLatitude: 39.5,
  },
  {
    id: 18,
    name: 'Çankırı',
    maxLongitude: 34.05,
    minLongitude: 32.34,
    maxLatitude: 40.91,
    minLatitude: 40.31,
  },
  {
    id: 19,
    name: 'Çorum',
    maxLongitude: 35.51,
    minLongitude: 34.08,
    maxLatitude: 41.27,
    minLatitude: 40.24,
  },
  {
    id: 20,
    name: 'Denizli',
    maxLongitude: 29.62,
    minLongitude: 28.42,
    maxLatitude: 38.32,
    minLatitude: 37.18,
  },
  {
    id: 21,
    name: 'Diyarbakır',
    maxLongitude: 40.3,
    minLongitude: 38.18,
    maxLatitude: 38.48,
    minLatitude: 37.31,
  },
  {
    id: 22,
    name: 'Edirne',
    maxLongitude: 26.87,
    minLongitude: 26.04,
    maxLatitude: 41.25,
    minLatitude: 40.71,
  },
  {
    id: 23,
    name: 'Elazığ',
    maxLongitude: 39.41,
    minLongitude: 38.14,
    maxLatitude: 39.41,
    minLatitude: 38.38,
  },
  {
    id: 24,
    name: 'Erzincan',
    maxLongitude: 40.02,
    minLongitude: 38.32,
    maxLatitude: 40.12,
    minLatitude: 39.18,
  },
  {
    id: 25,
    name: 'Erzurum',
    maxLongitude: 42.92,
    minLongitude: 40.34,
    maxLatitude: 40.55,
    minLatitude: 39.1,
  },
  {
    id: 26,
    name: 'Eskişehir',
    maxLongitude: 31.6,
    minLongitude: 29.98,
    maxLatitude: 39.84,
    minLatitude: 39.08,
  },
  {
    id: 27,
    name: 'Gaziantep',
    maxLongitude: 37.77,
    minLongitude: 36.37,
    maxLatitude: 37.41,
    minLatitude: 36.82,
  },
  {
    id: 28,
    name: 'Giresun',
    maxLongitude: 39.13,
    minLongitude: 37.67,
    maxLatitude: 41.22,
    minLatitude: 40.44,
  },
  {
    id: 29,
    name: 'Gümüşhane',
    maxLongitude: 40.05,
    minLongitude: 38.65,
    maxLatitude: 40.88,
    minLatitude: 39.82,
  },
  {
    id: 30,
    name: 'Hakkari',
    maxLongitude: 44.85,
    minLongitude: 43.41,
    maxLatitude: 38.36,
    minLatitude: 37.2,
  },
  {
    id: 31,
    name: 'Hatay',
    maxLongitude: 36.57,
    minLongitude: 35.65,
    maxLatitude: 37.31,
    minLatitude: 35.82,
  },
  {
    id: 32,
    name: 'Isparta',
    maxLongitude: 31.48,
    minLongitude: 30.18,
    maxLatitude: 38.18,
    minLatitude: 37.18,
  },
  {
    id: 33,
    name: 'Mersin',
    maxLongitude: 34.99,
    minLongitude: 32.52,
    maxLatitude: 37.74,
    minLatitude: 36.01,
  },
  {
    id: 34,
    name: 'İstanbul',
    maxLongitude: 29.91,
    minLongitude: 28.1,
    maxLatitude: 41.32,
    minLatitude: 40.8,
  },
  {
    id: 35,
    name: 'İzmir',
    maxLongitude: 27.42,
    minLongitude: 26.22,
    maxLatitude: 38.53,
    minLatitude: 37.73,
  },
  {
    id: 36,
    name: 'Kars',
    maxLongitude: 44.83,
    minLongitude: 42.97,
    maxLatitude: 41.38,
    minLatitude: 40.5,
  },
  {
    id: 37,
    name: 'Kastamonu',
    maxLongitude: 34.75,
    minLongitude: 32.19,
    maxLatitude: 41.89,
    minLatitude: 40.77,
  },
  {
    id: 38,
    name: 'Kayseri',
    maxLongitude: 35.88,
    minLongitude: 34.26,
    maxLatitude: 39.34,
    minLatitude: 37.88,
  },
  {
    id: 39,
    name: 'Kırklareli',
    maxLongitude: 27.3,
    minLongitude: 26.47,
    maxLatitude: 41.98,
    minLatitude: 41.33,
  },
  {
    id: 40,
    name: 'Kırşehir',
    maxLongitude: 34.62,
    minLongitude: 33.26,
    maxLatitude: 39.79,
    minLatitude: 38.85,
  },
  {
    id: 41,
    name: 'Kocaeli',
    maxLongitude: 30.04,
    minLongitude: 29.29,
    maxLatitude: 41.2,
    minLatitude: 40.47,
  },
  {
    id: 42,
    name: 'Konya',
    maxLongitude: 34.58,
    minLongitude: 31.14,
    maxLatitude: 39.11,
    minLatitude: 36.72,
  },
  {
    id: 43,
    name: 'Kütahya',
    maxLongitude: 30.53,
    minLongitude: 29.0,
    maxLatitude: 39.44,
    minLatitude: 38.28,
  },
  {
    id: 44,
    name: 'Malatya',
    maxLongitude: 39.27,
    minLongitude: 37.86,
    maxLatitude: 39.23,
    minLatitude: 37.87,
  },
  {
    id: 45,
    name: 'Manisa',
    maxLongitude: 28.99,
    minLongitude: 27.09,
    maxLatitude: 39.23,
    minLatitude: 38.08,
  },
  {
    id: 46,
    name: 'Kahramanmaraş',
    maxLongitude: 37.96,
    minLongitude: 36.35,
    maxLatitude: 38.41,
    minLatitude: 37.09,
  },
  {
    id: 47,
    name: 'Mardin',
    maxLongitude: 41.27,
    minLongitude: 39.77,
    maxLatitude: 38.08,
    minLatitude: 37.19,
  },
  {
    id: 48,
    name: 'Muğla',
    maxLongitude: 29.78,
    minLongitude: 27.16,
    maxLatitude: 37.29,
    minLatitude: 36.57,
  },
  {
    id: 49,
    name: 'Muş',
    maxLongitude: 42.63,
    minLongitude: 40.82,
    maxLatitude: 39.13,
    minLatitude: 38.41,
  },
  {
    id: 50,
    name: 'Nevşehir',
    maxLongitude: 35.04,
    minLongitude: 33.44,
    maxLatitude: 39.44,
    minLatitude: 38.5,
  },
  {
    id: 51,
    name: 'Niğde',
    maxLongitude: 35.36,
    minLongitude: 34.11,
    maxLatitude: 38.68,
    minLatitude: 37.74,
  },
  {
    id: 52,
    name: 'Ordu',
    maxLongitude: 38.27,
    minLongitude: 36.94,
    maxLatitude: 41.11,
    minLatitude: 40.33,
  },
  {
    id: 53,
    name: 'Rize',
    maxLongitude: 41.52,
    minLongitude: 40.29,
    maxLatitude: 41.45,
    minLatitude: 40.37,
  },
  {
    id: 54,
    name: 'Sakarya',
    maxLongitude: 30.7,
    minLongitude: 29.86,
    maxLatitude: 41.33,
    minLatitude: 40.42,
  },
  {
    id: 55,
    name: 'Samsun',
    maxLongitude: 37.19,
    minLongitude: 35.94,
    maxLatitude: 41.45,
    minLatitude: 40.47,
  },
  {
    id: 56,
    name: 'Siirt',
    maxLongitude: 42.01,
    minLongitude: 40.52,
    maxLatitude: 38.34,
    minLatitude: 37.69,
  },
  {
    id: 57,
    name: 'Sinop',
    maxLongitude: 35.47,
    minLongitude: 34.17,
    maxLatitude: 42.17,
    minLatitude: 41.14,
  },
  {
    id: 58,
    name: 'Sivas',
    maxLongitude: 38.4,
    minLongitude: 35.75,
    maxLatitude: 40.44,
    minLatitude: 38.62,
  },
  {
    id: 59,
    name: 'Tekirdağ',
    maxLongitude: 28.91,
    minLongitude: 26.96,
    maxLatitude: 41.04,
    minLatitude: 40.52,
  },
  {
    id: 60,
    name: 'Tokat',
    maxLongitude: 37.82,
    minLongitude: 35.23,
    maxLatitude: 40.78,
    minLatitude: 39.57,
  },
  {
    id: 61,
    name: 'Trabzon',
    maxLongitude: 40.0,
    minLongitude: 38.58,
    maxLatitude: 41.47,
    minLatitude: 40.7,
  },
  {
    id: 62,
    name: 'Tunceli',
    maxLongitude: 40.05,
    minLongitude: 38.3,
    maxLatitude: 39.21,
    minLatitude: 38.54,
  },
  {
    id: 63,
    name: 'Şanlıurfa',
    maxLongitude: 40.24,
    minLongitude: 37.88,
    maxLatitude: 38.57,
    minLatitude: 36.88,
  },
  {
    id: 64,
    name: 'Uşak',
    maxLongitude: 29.68,
    minLongitude: 28.95,
    maxLatitude: 39.31,
    minLatitude: 38.29,
  },
  {
    id: 65,
    name: 'Van',
    maxLongitude: 44.95,
    minLongitude: 42.96,
    maxLatitude: 39.08,
    minLatitude: 37.96,
  },
  {
    id: 66,
    name: 'Yozgat',
    maxLongitude: 36.03,
    minLongitude: 34.16,
    maxLatitude: 40.34,
    minLatitude: 39.1,
  },
  {
    id: 67,
    name: 'Zonguldak',
    maxLongitude: 32.37,
    minLongitude: 31.07,
    maxLatitude: 41.74,
    minLatitude: 40.86,
  },
  {
    id: 68,
    name: 'Aksaray',
    maxLongitude: 34.66,
    minLongitude: 33.26,
    maxLatitude: 39.05,
    minLatitude: 38.11,
  },
  {
    id: 69,
    name: 'Bayburt',
    maxLongitude: 40.27,
    minLongitude: 39.36,
    maxLatitude: 40.67,
    minLatitude: 39.95,
  },
  {
    id: 70,
    name: 'Karaman',
    maxLongitude: 34.59,
    minLongitude: 32.59,
    maxLatitude: 37.75,
    minLatitude: 36.89,
  },
  {
    id: 71,
    name: 'Kırıkkale',
    maxLongitude: 34.81,
    minLongitude: 33.56,
    maxLatitude: 40.1,
    minLatitude: 39.43,
  },
  {
    id: 72,
    name: 'Batman',
    maxLongitude: 41.54,
    minLongitude: 40.49,
    maxLatitude: 38.31,
    minLatitude: 37.67,
  },
  {
    id: 73,
    name: 'Şırnak',
    maxLongitude: 43.43,
    minLongitude: 42.14,
    maxLatitude: 38.42,
    minLatitude: 37.07,
  },
  {
    id: 74,
    name: 'Bartın',
    maxLongitude: 32.72,
    minLongitude: 31.43,
    maxLatitude: 41.9,
    minLatitude: 41.37,
  },
  {
    id: 75,
    name: 'Ardahan',
    maxLongitude: 43.48,
    minLongitude: 42.42,
    maxLatitude: 41.64,
    minLatitude: 40.74,
  },
  {
    id: 76,
    name: 'Iğdır',
    maxLongitude: 44.81,
    minLongitude: 43.27,
    maxLatitude: 40.26,
    minLatitude: 39.55,
  },
  {
    id: 77,
    name: 'Yalova',
    maxLongitude: 29.46,
    minLongitude: 29.1,
    maxLatitude: 40.8,
    minLatitude: 40.4,
  },
  {
    id: 78,
    name: 'Karabük',
    maxLongitude: 32.96,
    minLongitude: 32.23,
    maxLatitude: 41.51,
    minLatitude: 40.82,
  },
  {
    id: 79,
    name: 'Kilis',
    maxLongitude: 37.52,
    minLongitude: 36.65,
    maxLatitude: 37.47,
    minLatitude: 36.89,
  },
  {
    id: 80,
    name: 'Osmaniye',
    maxLongitude: 37.43,
    minLongitude: 36.05,
    maxLatitude: 38.06,
    minLatitude: 37.01,
  },
  {
    id: 81,
    name: 'Düzce',
    maxLongitude: 31.28,
    minLongitude: 30.79,
    maxLatitude: 41.07,
    minLatitude: 40.47,
  },
];
