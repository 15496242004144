import * as React from 'react';
import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import './styles.css';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import * as locales from 'react-date-range/dist/locale';
import { Add } from '@mui/icons-material';
import moment from 'moment';
import { TextField } from '@mui/material';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

function MyCalendar() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      key: 'selection',
    },
  ]);

  const [allDay, setAllDay] = useState(true);

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mt-10">
        <DateRange
          date={new Date()}
          locale={locales['tr']}
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          showDateDisplay={allDay ? true : false}
          ranges={state}
          direction="horizontal"
          color="#ffd300"
        />
        {/* <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            sx={{ marginTop: '10px' }}
            value={allDay}
            onChange={(e) => {
              setAllDay(e.target.checked);
            }}
            label="Tüm Gün"
          />
        </FormGroup> */}
      </div>

      {!allDay && (
        <div className="flex flex-row max-lg:flex-wrap max-lg:wrap justify-between">
          <div className="m-2 p-2 lg:ml-2 w-full">
            <div className="flex mb-5 justify-center items-center bg-white rounded-md shadow-md p-3 m-3">
              {moment(state[0].startDate.toDateString()).format('DD.MM.YYYY')}
            </div>
            <TimePicker
              slotProps={{ textField: { sx: { width: '100%' } } }}
              fullwidth
              value={state[0].startTime}
              onChange={(date) => {
                setState([{ ...state[0], startTime: date }]);
              }}
              ampm={false}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              label="Başlangıç Saati"
            />
          </div>
          <div className="m-2 p-2 lg:ml-2 w-full">
            <div className="flex mb-5 justify-center items-center bg-white rounded-md shadow-md p-3 m-3">
              {moment(state[0].endDate.toDateString()).format('DD.MM.YYYY')}
            </div>
            <TimePicker
              slotProps={{
                textField: { sx: { width: '100%', variant: 'standard' } },
              }}
              minTime={
                moment(state[0].startDate).isSame(state[0].endDate, 'day')
                  ? state[0].startTime
                  : null
              }
              onChange={(date) => {
                setState([{ ...state[0], endTime: date }]);
              }}
              value={state[0].endTime}
              fullwidth
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              ampm={false}
              label="Bitiş Saati"
            />
          </div>
        </div>
      )}

      {/* <div className="w-full flex flex-col justify-center items-center">
        <div className="w-full  p-2">
          <TextField variant="standard" label="Title" sx={{ width: '100%' }} />
        </div>
        <div className="w-full  p-2">
          <TextField
            variant="standard"
            label="Description"
            sx={{ width: '100%' }}
          />
        </div>
      </div>
      <div className="my-4 flex flex-row justify-center items-center ">
        <div className="w-full">
          <Button fullWidth startIcon={<Add />} variant="contained">
            ETKİNLİK OLUŞTUR
          </Button>
        </div>
      </div> */}
    </>
  );
}

export default MyCalendar;
