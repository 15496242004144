import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import IsLoggedIn from './IsLoggedIn';
import useAuthStore from '../stores/auth-store';
import { EnterLayoutPage, LoginPage } from '../pages';
import { useNavigate } from 'react-router-dom';

function MainContainer() {
  localStorage.setItem('user', JSON.stringify({ user: { id: 'pusula' } }));
  localStorage.setItem('permissions', JSON.stringify(['*']));

  return (
    <Router>
      <IsLoggedIn />
    </Router>
  );
}

export default MainContainer;
