import {
  EnterLayoutPage,
  LoginPage,
  ErrorPage,
  Shifts,
  Geocode,
} from '../pages';

const routes = [
  {
    path: '/',
    element: <Shifts />,
  },
  {
    path: '/login',
    element: (
      <EnterLayoutPage>
        <LoginPage />
      </EnterLayoutPage>
    ),
  },
  {
    path: '*',
    element: <ErrorPage />,
  },

  {
    path: '/users/distribution',
    element: <Shifts />,
  },
  /*{
    path: '/users/geoconvert',
    element: <Geocode />,
  },*/
];

export default routes;
