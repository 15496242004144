import en from '../langs/en.json';
import de from '../langs/de.json';
import tr from '../langs/tr.json';
/* const langMap1 = [
   ['tr', tr],
  ['de', de],
  ['en', en], 
  tr = 'Türkçe',
  en = 'İngilizce' ,
]; */

const langMap = new Map([
  ['tr', tr],
  ['en', en],
]);
export { langMap };
