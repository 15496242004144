const Layout = ({ children }) => {
  return (
    <div className="bg-back flex h-screen w-screen bg-cover bg-center">
      <div className="flex h-screen flex-col w-full items-center justify-center bg-yerlemLogo  bg-[length:1000px_1000px] bg-no-repeat bg-center">
        <div className="bg-logoIcon w-[232px] h-[65px] bg-no-repeat bg-center bg-cover mb-10"></div>
        {children}
      </div>
    </div>
  );
};

export default Layout;
