import {
  ChildCare,
  Dashboard,
  DevicesOther,
  DirectionsCar,
  Engineering,
  Group,
  Inventory,
  Route,
  SafetyCheck,
  SupervisedUserCircle,
  Troubleshoot,
  List,
  EmojiTransportation,
  DirectionsBus,
  EventSeat,
  CreditScore,
  TransferWithinAStation,
  AirportShuttle,
  LocalGasStation,
} from '@mui/icons-material';

export const items = [
  {
    id: 0,
    parent: 'Konum',
    to: '/users/distribution',
    child: [],
    icon: <Inventory />,
  },
  /*
  {
    id: 1,
    parent: 'Pusula',
    to: '/users/geoconvert',
    child: [
      {
        id: 0,
        parent: 'Geocoder',
        to: '/users/geoconvert',
        icon: <Engineering />,
        child: null,
      },
    ],
  } 
  {
    id: 1,
    parent: 'TANIMLAMALAR',
    to: '/users/distribution',
    icon: <Engineering />,
    child: [
      {
        id: 0,
        parent: 'Dağıtım Oluştur',
        to: '/users/distribution',
        icon: <DirectionsCar />,
        child: null,
      },
      {
        id: 1,
        parent: 'Rotalar',
        to: '/users/routes/newroute',
        icon: <DirectionsCar />,
        child: [
          {
            id: 0,
            to: '/users/routes/newroute',
            name: 'Yeni Rota',
          },
          {
            id: 1,
            to: '/users/routes/history',
            name: 'Geçmiş Rotalar',
          },
          {
            id: 2,
            to: '/users/routes/activeroute',
            name: 'Aktif Rotalar',
          },
         
        ],
      },
    ],
  },*/,
  // {
  //   id: 2,
  //   parent: 'ATLAS',
  //   icon: <Route />,
  //   to: '/atlas/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/atlas/dashboard',
  //       name: 'Dashboard',
  //       icon: <Dashboard />,
  //     },
  //     {
  //       id: 1,
  //       to: '/atlas/teams',
  //       name: 'Takımlar',
  //       icon: <Group />,
  //     },
  //     {
  //       id: 2,
  //       to: '/atlas/observers',
  //       name: 'Gözlemciler',
  //       icon: <Troubleshoot />,
  //     },
  //     // {
  //     //   id: 3,
  //     //   to: '/telematics/entities',
  //     //   name: 'Varlıklar',
  //     // },
  //     // {
  //     //   id: 4,
  //     //   to: '/telematics/alarms',
  //     //   name: 'Alarmlar',
  //     // },
  //   ],
  // },
  // {
  //   id: 3,
  //   parent: 'FLEET',
  //   to: '/fleet/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/fleet/dashboard',
  //       name: 'Dashboard',
  //     },
  //     {
  //       id: 1,
  //       to: '/fleet/teams',
  //       name: 'Takımlar',
  //     },
  //     {
  //       id: 2,
  //       to: '/fleet/observers',
  //       name: 'Gözlemciler',
  //     },
  //     {
  //       id: 3,
  //       to: '/fleet/entities',
  //       name: 'Varlıklar',
  //     },
  //     {
  //       id: 4,
  //       to: '/fleet/alarms',
  //       name: 'Alarmlar',
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   parent: 'UMAY',
  //   icon: <ChildCare />,
  //   to: '/umay/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       parent: 'DASHBOARD',
  //       to: '/umay/dashboard',
  //       icon: <Dashboard />,
  //     },
  //     {
  //       id: 1,
  //       parent: 'TAKIMLAR',
  //       to: '/umay/teams',
  //       icon: <Group />,
  //       child: [
  //         {
  //           id: 0,
  //           to: '/umay/teams/index-four',
  //           name: 'Index Four',
  //           instanceCanView: ['amir-tr0'],
  //           icon: <List />,
  //         },
  //         {
  //           id: 1,
  //           to: '/umay/teams/index-five',
  //           name: 'Index Five',
  //           instanceCanView: ['amir-tr0'],
  //           icon: <List />,
  //         },
  //         {
  //           id: 0, // amir-al0 için ilk id
  //           to: '/umay/teams/index-six',
  //           name: 'Index Six',
  //           instanceCanView: ['amir-al0'],
  //           icon: <List />,
  //         },
  //       ],
  //     },
  //     {
  //       id: 2,
  //       parent: 'GÖZLEMCİLER',
  //       to: '/umay/observers',
  //       icon: <Troubleshoot />,
  //       child: [
  //         {
  //           id: 0,
  //           to: '/umay/observers/index-seven',
  //           name: 'Index Seven',
  //           icon: <List />,
  //         },
  //         {
  //           id: 1,
  //           to: '/umay/observers/index-eight',
  //           name: 'Index Eight',
  //           icon: <List />,
  //         },
  //         {
  //           id: 2,
  //           to: '/umay/observers/index-nine',
  //           name: 'Index Nine',
  //           icon: <List />,
  //         },
  //       ],
  //     },
  //     // {
  //     //   id: 3,
  //     //   parent: 'VARLIKLAR',
  //     //   to: '/umay/entities/1',
  //     //   child: [
  //     //     {
  //     //       id: 0,
  //     //       to: '/umay/entities/index-ten',
  //     //       name: 'Index Ten',
  //     //     },
  //     //     {
  //     //       id: 1,
  //     //       to: '/umay/entities/index-eleven',
  //     //       name: 'Index Eleven',
  //     //     },
  //     //     {
  //     //       id: 2,
  //     //       to: '/umay/entities/index-twelve',
  //     //       name: 'Index Twelve',
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   id: 4,
  //     //   parent: 'ALARMLAR',
  //     //   to: '/umay/alarms',
  //     //   child: [
  //     //     {
  //     //       id: 0,
  //     //       to: '/umay/alarms/index-thirteen',
  //     //       name: 'Index Thirteen',
  //     //     },
  //     //     {
  //     //       id: 1,
  //     //       to: '/umay/alarms/index-fourteen',
  //     //       name: 'Index Fourteen',
  //     //     },
  //     //     {
  //     //       id: 2,
  //     //       to: '/umay/alarms/index-fifteen',
  //     //       name: 'Index Fifteen',
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  // {
  //   id: 4,
  //   parent: 'KAVİ',
  //   icon: <SafetyCheck />,
  //   to: '/kavi/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/kavi/dashboard',
  //       name: 'Dashboard',
  //       icon: <Dashboard />,
  //     },
  //     {
  //       id: 1,
  //       name: 'Görev Güvenlik Raporları',
  //       to: '/kavi/transportation-safety-reports',
  //       instanceCanView: ['amir-al0'],
  //       icon: <EventSeat />,
  //     },
  //     {
  //       id: 2,
  //       name: 'Araç Güvenlik Raporları',
  //       to: '/kavi/vehicle-safety-reports',
  //       instanceCanView: ['amir-al0'],
  //       icon: <DirectionsBus />,
  //     },
  //     {
  //       id: 3,
  //       name: 'Filo Güvenlik Raporları',
  //       to: '/kavi/fleet-safety-reports',
  //       instanceCanView: ['amir-al0'],
  //       icon: <EmojiTransportation />,
  //     },
  //     {
  //       id: 1, //  amir-tr0 için ilk id
  //       name: 'Kart Raporları',
  //       to: '/kavi/card-reports',
  //       instanceCanView: ['amir-tr0'],
  //       icon: <TransferWithinAStation />,
  //     },
  //     {
  //       id: 2, //  amir-tr0 için ikinci id
  //       name: 'Görev Raporları',
  //       to: '/kavi/voyage-reports',
  //       instanceCanView: ['amir-tr0'],
  //       icon: <AirportShuttle />,
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   parent: 'FUEL',
  //   icon: <LocalGasStation />,
  //   to: '/fuel/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/fuel/dashboard',
  //       name: 'Dashboard',
  //       icon: <Dashboard />,
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   parent: 'BİLDİRİMLER',
  //   to: '/notifications/dashboard',
  //   child: [
  //     {
  //       id: 0,
  //       to: '/notifications/dashboard',
  //       name: 'Dashboard',
  //     },
  //     {
  //       id: 1,
  //       to: '/notifications/index-five',
  //       name: 'Index Five',
  //     },
  //     {
  //       id: 2,
  //       to: '/notifications/index-six',
  //       name: 'Index Six',
  //     },
  //   ],
  // },
];
