import React from 'react';
import useAuthStore from '../../stores/auth-store';

const PermissionToRender = ({
  permissionCanView,
  instanceCanView,
  roleCanView,
  children,
}) => {
  const permissions = useAuthStore();
  const roles = JSON.parse(localStorage.getItem('permissions'))?.data?.data
    ?.roles;
  const instance = JSON.parse(localStorage.getItem('instance'));
  const selectedInstance = JSON.parse(localStorage.getItem('selectedInstance'));

  const hasPermission = (requiredPermission) => {
    return permissions.includes(requiredPermission);
  };

  const canAccessInstance = (allowedInstances) => {
    return allowedInstances.includes(
      selectedInstance ? selectedInstance[0].id : instance[0].instance.id,
    );
  };

  const hasRole = (requiredRole) => {
    return roles.includes(requiredRole);
  };

  const renderChildren = () => {
    if (
      (permissionCanView && !hasPermission(permissionCanView)) ||
      (instanceCanView && !canAccessInstance(instanceCanView)) ||
      (roleCanView && !hasRole(roleCanView)) ||
      false
    ) {
      return null;
    }
    return children;
  };

  return <>{renderChildren()}</>;
};

export default PermissionToRender;
