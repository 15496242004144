import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import createLangStore from './lang-store';
import createErrorStore from './error-store';
import createComponentStateStore from './component-state-store';
import createAppConfigStore from './app-config-store';
import createWsConfigStore from './ws-config-store';
export const useBoundStore = create(
  devtools(
    persist(
      (...a) => ({
        ...createLangStore(...a),
        ...createErrorStore(...a),
        ...createComponentStateStore(...a),
        ...createAppConfigStore(...a),
        ...createWsConfigStore(...a),
      }),
      {
        name: 'bound-store',
        partialize: (state) => ({ lang: state.language }),
      },
    ),
  ),
);
