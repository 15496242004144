import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
export const columns = [
  {
    field: 'routingTitle',
    headerName: 'Rotanın Adı',
    width: 400,
    renderCell: (data) => {
      return <span>{data.row.routingTitle}</span>;
    },
  },
  {
    field: 'routingShiftId',
    headerName: 'Rotanın Vardiyası',
    width: 200,
    renderCell: (data) => {
      return (
        <span>
          {data.row.routingShiftId == '1'
            ? 'Sabah'
            : data.row.routingShiftId == '2'
              ? 'Öğle'
              : data.row.routingShiftId == '3'
                ? 'Akşam'
                : data.row.routingShiftId == '4'
                  ? 'Memur'
                  : 'Tanımsız'}
        </span>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Rotanın Tarihi',
    width: 240,
    renderCell: (data) => {
      const timestamp = data.row.createdAt;
      const date = new Date(timestamp * 1000); // Convert to milliseconds

      const formattedDate = date.toLocaleDateString('tr-TR', {
        timeZone: 'UTC',
      });

      const formattedTime = date.toLocaleTimeString('tr-TR', {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });

      return (
        <span>
          {formattedDate} {formattedTime}
        </span>
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Göster',
    width: 100,
    sortable: false,
    renderCell: (data) => {
      const onClick = () => {
        data.onSelectRoute(data.row);
      };

      return (
        <div className="flex justify-center items-center">
          <IconButton onClick={onClick} href={`#map`}>
            <LocationOnIcon />
          </IconButton>
        </div>
      );
    },
  },
  /* {
    field: 'actions',
    headerName: 'İşlem',
    width: 60,
    sortable: false,
    renderCell: (data) => {
      const onClick = () => {
        const parsedData = data.row.taskRoute);
        const distance = parsedData.paths[0].distance / 1000;
        const points = parsedData.paths[0].points.coordinates;
        const selectedTaskdata = data.row.taskData);
        data.handleClickTask(distance, points, selectedTaskdata);
      };

      return (
        <div className="flex justify-center items-center">
          <IconButton onClick={onClick} href={`#map`}>
            <LocationOnIcon />
          </IconButton>
        </div>
      );
    },
  },*/
];

export const filterList = [
  { value: 'id', text: 'Kullanıcı ID' },
  { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'email', text: 'Email' },
  { value: 'gsm', text: 'Telefon' },
];
