const updateLocalStorage = (appConfig) => {
  localStorage.setItem('appConfig', JSON.stringify(appConfig));
};

const createAppConfigStore = (set) => ({
  appConfig: localStorage.getItem('appConfig')
    ? JSON.parse(localStorage.getItem('appConfig'))
    : null,
  setAppConfig: (appConfig) => {
    set(() => ({ appConfig }));
    updateLocalStorage(appConfig);
  },
});

export default createAppConfigStore;
