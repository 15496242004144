import { langMap } from '../../constants/lang';

const navigatorLang = navigator.language.split('-')[0];
const localStorageLang = JSON.parse(localStorage.getItem('bound-store'))?.state
  ?.lang;
const createLangStore = (set) => ({
  language: localStorageLang || navigatorLang,
  textPool: langMap.get(localStorageLang || navigatorLang),
  changeLanguage: (value) => {
    document.documentElement.lang = value;
    set({
      language: value,
      textPool: langMap.get(value),
    });
  },
});

export default createLangStore;
